import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  redirectUrl: string;

  constructor(private http: HttpClient, private router: Router) {
  }

  login(user: { email: string, password: string }) {
    return this.http.post<any>(`${ environment.apiUrl }/auth/login`, user)
      .pipe(
        tap(tokens => {
          localStorage.setItem('ACCESS_TOKEN', tokens.accessToken);
          localStorage.setItem('REFRESH_TOKEN', tokens.refreshToken);
        })
      );
  }

  logout() {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        refreshToken: this.getRefreshToken()
      }
    };

    return this.http.delete<any>(`${ environment.apiUrl }/auth/logout`, options)
      .pipe(
        tap(() => {
          localStorage.removeItem('ACCESS_TOKEN');
          localStorage.removeItem('REFRESH_TOKEN');
          this.router.navigate(['/login']);
        })
      );
  }

  forgotPassword(email: { email: string }) {
    return this.http.post<any>(`${ environment.apiUrl }/auth/forgot`, email);
  }

  resetPassword(request: { id: string, password: string }) {
    return this.http.patch<any>(`${ environment.apiUrl }/auth/reset`, request);
  }

  refreshToken() {
    return this.http.post<any>(`${ environment.apiUrl }/auth/refresh`, { refreshToken: this.getRefreshToken() })
      .pipe(
        tap(tokens => {
          localStorage.setItem('ACCESS_TOKEN', tokens.accessToken);
        })
      );
  }

  verify() {
    return this.http.get<any>(`${ environment.apiUrl }/auth/verify`).pipe(
      map(res => res.verified)
    );
  }

  getAccessToken() {
    return localStorage.getItem('ACCESS_TOKEN');
  }

  getRefreshToken() {
    return localStorage.getItem('REFRESH_TOKEN');
  }
}
