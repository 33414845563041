import { Injectable, OnDestroy } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable, Subscription, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { NotificationService } from '../services/notification.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor, OnDestroy {
  private subscriptions: Subscription[] = [];
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private authService: AuthService, private notificationService: NotificationService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      if (err instanceof HttpErrorResponse && err.status === 401) {
        if (err.url === `${ environment.apiUrl }/auth/refresh`) {
          this.subscriptions.push(this.authService.logout().subscribe());
          const error = err.error.message || err.error || err.statusText;
          return throwError(error);
        } else {
          return this.handle401Error(request, next);
        }
      } else {
        const error = err.error.message || err.error || err.statusText;
        this.notificationService.openErrorSnackBar(error, 'Stäng');
        return throwError(error);
      }
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshToken().pipe(
        switchMap((token: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token.accessToken);
          return next.handle(request.clone({
            setHeaders: {
              Authorization: `Bearer ${ token.accessToken }`
            }
          })).pipe(catchError(err => {
            const error = err.error.message || err.error || err.statusText;
            this.notificationService.openErrorSnackBar(error, 'Stäng');
            return throwError(error);
          }));
        }));
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap((accessToken: any) => {
          return next.handle(request.clone({
            setHeaders: {
              Authorization: `Bearer ${ accessToken }`
            }
          })).pipe(catchError(err => {
            const error = err.error.message || err.error || err.statusText;
            this.notificationService.openErrorSnackBar(error, 'Stäng');
            return throwError(error);
          }));
        }));
    }
  }
}
