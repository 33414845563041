import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { User } from '../models/user.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) {
  }

  getAllUsers(): Observable<User[]> {
    return this.http.get<User[]>(`${ environment.apiUrl }/users`);
  }

  addUser(user: any) {
    return this.http.post<any>(`${ environment.apiUrl }/users/add`, {
      name: user.name,
      email: user.email,
      password: user.password,
      roles: user.roles
    });
  }

  updateUser(user: any) {
    if (user.password === '') {
      delete user.password;
    }
    return this.http.put<any>(`${ environment.apiUrl }/users/update/${ user.id }`, {
      name: user.name,
      email: user.email,
      password: user.password,
      roles: user.roles
    });
  }

  deleteUser(user: any) {
    return this.http.delete<any>(`${ environment.apiUrl }/users/delete/${ user.id }`);
  }

  getCurrentUser(): Observable<User> {
    return this.http.get<User>(`${ environment.apiUrl }/users/user`);
  }

  updateCurrentUserEmail(user: any) {
    return this.http.put<any>(`${ environment.apiUrl }/users/update/email`, {
      email: user.email,
      password: user.password
    });
  }

  updateCurrentUserPassword(user: any) {
    return this.http.put<any>(`${ environment.apiUrl }/users/update/password`, {
      old_password: user.old_password,
      password: user.password,
      password_again: user.password_again
    });
  }
}
